$fontDefault: var(--font-family);
$fontSize: var(--font-size);

.body-container{
  display: flex;
}
.body-container_right{
  width: 100%;
}
.content-container{
  flex: 1 0 auto;
  background-color: $white;
  padding: 15px;
  position: relative;
  width: 100%;
  font-size: 14px;
}

.sidebar-divider{
  border-top: 1px solid rgba(255,255,255,.15);
  margin: 0 1rem 1rem;
}

.form.control-label {
  padding-top: 18px !important;
}

.txt-two-line {
  max-height: 2.8rem;
  line-height: 1.4rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clsfx {
  clear: both;
}
.no-margin {
  margin: 0 !important;
}
.mg5 {
  margin: 5px !important;
}
.mgt5 {
  margin-top: 5px !important;
}
.mgr5 {
  margin-right: 5px !important;
}
.mgb5 {
  margin-bottom: 5px !important;
}
.mgl5 {
  margin-left: 5px !important;
}

.mg10 {
  margin: 10px !important;
}
.mgt10 {
  margin-top: 10px !important;
}
.mgr10 {
  margin-right: 10px !important;
}
.mgb10 {
  margin-bottom: 10px !important;
}
.mgl10 {
  margin-left: 10px !important;
}

.mg15 {
  margin: 15px !important;
}
.mgt15 {
  margin-top: 15px !important;
}
.mgr15 {
  margin-right: 15px !important;
}
.mgb15 {
  margin-bottom: 15px !important;
}
.mgl15 {
  margin-left: 15px !important;
}

//padding..
.no-padding {
  padding: 0 !important;
}
.pd5 {
  padding: 5px !important;
}
.pdt5 {
  padding-top: 5px !important;
}
.pdr5 {
  padding-right: 5px !important;
}
.pdb5 {
  padding-bottom: 5px !important;
}
.pdl5 {
  padding-left: 5px !important;
}

.pd10 {
  padding: 10px !important;
}
.pdt10 {
  padding-top: 10px !important;
}
.pdr10 {
  padding-right: 10px !important;
}
.pdb10 {
  padding-bottom: 10px !important;
}
.pdl10 {
  padding-left: 10px !important;
}

.pd15 {
  padding: 15px !important;
}
.pdt15 {
  padding-top: 15px !important;
}
.pdr15 {
  padding-right: 15px !important;
}
.pdb15 {
  padding-bottom: 15px !important;
}
.pdl15 {
  padding-left: 15px !important;
}

/* Border */
.no-border {
  border: 0 !important;
}

.border {
  border: 1px solid #ddd;
}

.bdt {
  border-top: 1px solid #ddd;
}

.bdb {
  border-bottom: 1px solid #ddd;
}

.bdl {
  border-left: 1px solid #ddd;
}

.bdr {
  border-right: 1px solid #ddd;
}

.cursor-pointer {
  cursor: pointer;
}
.fixed {
  position: sticky;
  top: 50px;
}

@media (min-width: 992px) {
  .no-pdr {
    padding-right: 0 !important;
  }
  .no-pdl {
    padding-left: 0 !important;
  }
}
