/**
* @copyright 2019 @ DigiNet
* @author TRIHAO
* @create 11/13/2019
* @Example
*/

@import "modal";

.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 6px) scale(1) !important;
}

.MuiInputLabel-root.MuiInputLabel-shrink {
  transform: scale(1);
}

.MuiFormLabel-root,
.MuiFormControlLabel-root {
  margin: 0;
}

.MuiDialog-paperScrollBody {
  vertical-align: top !important;
}

.MuiDialogContent-root {
  padding: 15px !important;
}

.MuiFormControl-root {
  .dx-texteditor.dx-editor-underlined {
    .dx-texteditor-input-container {
      //input {
      //  padding: 3px 0 3px;
      //}
    }
  }
  .dx-texteditor.dx-editor-outlined {
    box-shadow: inset 0 0 0 1px $subTextColor;
    input {
      padding: 10.5px 14px 10.5px;
    }

    .dx-texteditor-buttons-container + .dx-texteditor-input-container {
      input,
      .dx-placeholder:before {
        padding-left: 0;
      }
    }
  }
  .dx-texteditor.dx-editor-filled {
    .dx-texteditor-input-container {
      input {
        padding: 23px 12px 6px;
      }
      .dx-placeholder {
        top: 2px;
        left: -1px;
      }
    }
    .dx-texteditor-buttons-container + .dx-texteditor-input-container {
      input {
        padding-left: 0;
      }
      .dx-placeholder:before {
        padding-left: 0;
      }
    }
  }

  .dx-tagbox.dx-editor-filled .dx-texteditor-input,
  .dx-tagbox.dx-editor-outlined .dx-texteditor-input,
  .dx-tagbox.dx-editor-outlined .dx-tag {
    margin-top: 0;
  }

  .dx-tagbox.dx-editor-filled .dx-tag-container,
  .dx-tagbox.dx-editor-outlined .dx-tag-container {
    padding: 3px 0;
    min-height: 37px;
  }

  .dx-tagbox .dx-tag .dx-tag-content {
    padding: 7px 32px 5px 12px;
  }

  .dx-tagbox .dx-texteditor-input {
    height: 30px;
  }

  .MuiInputLabel-outlined {
    background-color: $white;
    padding: 0 4px;
  }
}
.MuiFormControl-marginDense {
  margin-top: 4px;
  margin-bottom: 4px;
}

.MuiFab-primary,
.MuiFab-secondary {
  svg {
    path,
    circle,
    line {
      fill: $white;
    }
  }
}

.sub-combo-with-icon.MuiFormControl-marginDense {
  // margin-top: 4px;
  // margin-bottom: 4px;
  .dx-texteditor {
    &.dx-editor-underlined {
      margin-top: 7px !important;
    }
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}

.MuiFilledInput-root {
  background-color: rgba(0, 0, 0, 0.04) !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.07) !important;
  }
}

.MuiFab-extended.MuiFab-sizeSmall {
  padding: 0 12px !important;
}

.MuiDialogContent-dividers {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.skeleton-form {
  margin-top: 8px;
  margin-bottom: 4px;
  height: 42px !important;
}

.dx-dropdownbox.dx-editor-outlined.dx-dropdowneditor-active {
  -webkit-box-shadow: inset 0 0 0 1px $primaryColor;
  box-shadow: inset 0 0 0 1px $primaryColor;
}

.dx-popup-content {
  //padding: 15px !important;
}

.dx-treeview-item {
  min-height: 40px;
  line-height: 18px;
}

.MuiListItem-button:hover {
  background-color: $hoverColorDefault !important;
}

.MuiAutocomplete-popper {
  z-index: 1502 !important;
}

.braction,
.braction .MuiFormControl-root {
  margin: 0;

  .MuiInputLabel-formControl:not(.MuiInputLabel-outlined) {
    transform: translate(0, 19px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(0, 1.5px) scale(0.75);
      transform-origin: top left;
    }
  }

  input {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
  }

  .MuiOutlinedInput-root {
    //border-radius: 0;
  }

  label + .MuiInput-formControl {
    margin-top: 10px;
  }
}

.dx-datebox {
  .dx-texteditor-buttons-container > .dx-button.dx-button-mode-text {
    margin: 0;
  }
  &.dx-state-focused .dx-button-normal.dx-button-mode-text .dx-icon.dx-icon-event {
    color: $primaryColor;
  }
}

.filter-input {
  .dx-texteditor.dx-editor-filled.dx-state-hover:after {
    border: none;
  }
}

.btn-action {
  svg.btn-icon-svg {
    width: 16px;
    height: 12px;
  }
  &.Mui-disabled {
    &:not(.bg_none) {
      background-color: #e7eaf0 !important;
    }
    color: #8f9bb3 !important;
    &.MuiButton-outlined {
      border: 1px solid #8f9bb3 !important;
    }
    svg {
      path,
      circle,
      line {
        fill: #8f9bb3 !important;
        stroke: #8f9bb3 !important;
      }
    }
  }
  &.MuiButton-containedPrimary {
    svg {
      path,
      circle,
      line {
        fill: $fontColor;
        stroke: $fontColor;
      }
    }
  }
  &.MuiButton-textPrimary {
    svg {
      path,
      circle,
      line {
        fill: $primaryColor;
      }
    }
  }
  &.MuiButton-textSecondary {
    svg {
      path,
      circle,
      line {
        fill: $secondaryColor;
      }
    }
  }
  &.MuiButton-textInherit {
    svg {
      path,
      circle,
      line {
        fill: $fontColorDefault;
      }
    }
  }
}

.MuiFormControl-root.MuiFormControl-marginNormal,
.MuiFormControl-root.MuiFormControl-marginDense {
  & > label + .dx-texteditor {
    &.dx-editor-underlined {
      margin-top: 16px;
      .dx-button-normal.dx-button-mode-text {
        margin-top: -1px;
      }
    }
    &.dx-editor-filled {
      &:before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.87);
      }
      input {
        padding: 27px 12px 10px;
      }
    }
  }
}

//.MuiButton-root.MuiButton-textPrimary, .MuiButton-root.MuiButton-textSecondary {
//  &:hover, &.btn-loading {
//    background-color: #9999993d;
//  }
//}

.MuiButtonBase-root.btn-add-w75f2010Popup {
  .MuiIconButton-label {
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
      width: 0.688em;
      height: 1.313em;
    }
  }
}

.MuiButton-outlinedSizeLarge,
.MuiButton-textSizeLarge {
  font-size: 1rem !important;
}

.braction {
  &.braction-not-bd {
    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
  }

  &.braction-bdlr {
    .MuiOutlinedInput-root {
      border-radius: 0;
    }
    .MuiOutlinedInput-notchedOutline {
      border-top: none !important;
      border-bottom: none !important;
    }
  }
}

.MuiInputLabel-asterisk {
  color: red;
}

//create button custom material ui..
.btn-custom {
  background-color: #ebebeb;
  &:not(.Mui-disabled) svg {
    path,
    line,
    circle {
      fill: rgba(0, 0, 0, 0.87);
    }
  }
  &:hover,
  &.btn-loading {
    background-color: #d4d4d4;
  }
  &.MuiButton-textPrimary {
    &:not(.Mui-disabled) svg {
      path,
      line,
      circle {
        fill: $primaryColor;
      }
    }
    &:hover,
    &.btn-loading {
      background-color: $primaryHoverColor;
    }
  }
  &.MuiButton-textSecondary {
    &:not(.Mui-disabled) svg {
      path,
      line,
      circle {
        fill: $secondaryColor;
      }
    }
    &:hover, &.btn-loading {
      background-color: $secondaryHoverColor;
    }
  }
}

.MuiDialog-root.modal-review-file {
  .MuiDialogContent-root {
    padding: 0px 0px !important;
  }
}

.dx-editor-filled .dx-texteditor-buttons-container:first-child > .dx-button:first-child,
.dx-editor-outlined .dx-texteditor-buttons-container:first-child > .dx-button:first-child {
  margin-left: 0;
}
.dx-editor-filled .dx-texteditor-buttons-container:last-child > .dx-dropdowneditor-button:last-child,
.dx-editor-outlined .dx-texteditor-buttons-container:last-child > .dx-dropdowneditor-button:last-child {
  margin-right: 5px;
}

.MuiTab-root:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  border-radius: 1px;
  width: 100%;
  background-color: #E4E9F2;
}

.Mui-disabled {
  .icons-ui {
    color: #8F9BB3;
    circle {
      fill: #8F9BB3
    }
  }
}

.MuiFormControl-root {
  & .Mui-error {
    color: $dangerColor !important;
    .MuiCheckbox-root:not(.Mui-disabled) {
      color: #f44336;
    }
  }
}

.MuiInput-underline:after {
  border-bottom: 2px solid $infoColor !important;
}
