$fontColorDefault: $fontColorDefault;
$fontColor: $fontColor;
$defaultColor: $defaultColor;
$primaryColor: $primaryColor;
$primaryLightColor: $hoverColorDefault;
$primaryFontColor: $primaryFontColor;
$secondaryColor: $secondaryColor;
$secondaryFontColor: $secondaryFontColor;
$widthSideBar: $widthSideBar;
$heightHeader: $heightHeader;

//Customize css....
.filter-input {
  .dx-dropdowneditor-active {
    background-color: rgb(255, 255, 255) !important;
    box-shadow: 0px -2px 6px 4px rgba(0, 0, 0, 0.06);
    &:before {
      transform: scale(1);
      left: auto;
      right: auto;
      height: 1px;
      display: block;
      width: calc(100% - 40px);
      margin: 0 20px;
    }
  }
}

.popup-choose-theme {
  .MuiList-subheader {
    svg path {
      fill: $primaryColor
    }
  }
}

.dx-button-has-icon img.dx-icon {
  width: 20px;
  height: 20px;
}

.wrapper-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF88;
}

.popup-notify {
  padding: 0 !important;
  .header-title-notify {
    color: $fontColorDefault;
    margin-bottom: -4px !important;
    font-size: 1.5rem;
    font-family: 'SF Display','Font Awesome 5 Free',sans-serif;
    font-weight: 400;
    line-height: 1.33;
  }

  .header-tabs-notify {
    .MuiTab-textColorPrimary.Mui-selected {
      color: $primaryColor;
      svg {
        path, line, circle {
          fill: $primaryColor;
        }
      }
    }
    .MuiTabs-indicator {
      background-color: $primaryColor;
    }
  }

  .popup-content-notify {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    .item-notify {
      cursor: pointer;
      padding: 10px 15px;
      &:hover {
        background-color: $hoverColorDefault;
      }
      .item-notify-avatar {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
      .time {
        font-size: 0.7rem;
        font-weight: 200;
        color: $fontColorDefault;
      }
    }
  }
}

.popup-notify.popup-tooltip {
  z-index: 1502;
  top: 40px;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.23);
    position: absolute;
    right: -5px;
    top: -4px;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 11px solid white;
    position: absolute;
    right: -4px;
    top: -3px;
  }
}

.popup-notify {
  .item-notify .sender_name {
    color: $fontColorDefault
  }
}

.dx-popup-normal .dx-popup-content {
  height: 100%;
  padding: 1px;
}

.dx-list {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.dx-texteditor-input {
  color: $primaryColor;
}

.dx-dropdowneditor-input-wrapper {
  overflow: visible;
}

.dx-dropdowneditor-icon {
  color: $subTextColor;
  &::before {
    content: "\f016";
  }
}

.dx-popover-wrapper .dx-popover-arrow:after,
.dx-popover-wrapper.dx-popover-without-title .dx-popover-arrow:after {
  background-color: $white;
}

.date-text-info {
  font-weight: 400;
  color: $subTextColor;
}
