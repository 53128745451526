$heightItem: 42px;
$widthSideBar: 78px;
$heightHeader: 49px;
.side-nav {
  padding-top: $heightHeader;
  flex: 1 0 auto;
}
.side-container{
  display: flex;
  flex-direction: column;
  transition: all 0.1s;
  color: $white;
  width: 273px;
  min-width: 243px;
  max-width: 80%;
  border-right: 1px solid #E0E0E0;
  font-family: $fontDefault, sans-serif;
  position: fixed;
  z-index: 1010;
  left: 0;
  top: 0;
  max-height: 100%;
  height: 100%;
  margin-top: $heightHeader;

  .btn-top-menu {
    display: none;
  }

  &:not(.not-shadow) {
    box-shadow: 0 12px 15px lightgrey;
  }

  .fas{
    font-weight: 400;
  }

  .menu-top:not(.menu-top.minimum){
    background-color: lightgrey;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
  }
  .menu-top{
    overflow: hidden;
  }

  .group-menu-ess{
    display: none;
    margin-bottom: 15px;
    .MuiButtonGroup-contained{
      max-width: 90%;
      width: 200px;
      height: 36px;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: none;
      border: 1px solid $hoverColorDefault;
    }
    .MuiButton-contained{
      border-width: 0;
      min-width: auto;
      width:50%;
      max-height:100%;
      .MuiButton-label{
        max-height: 100%;
        height: 100%;
        min-height: 0;
        font-size: 1rem;
      }
    }
    .ess-active{
      background-color: $primaryColor;
      span{
        color: white;
      }
    }
    .ess-inactive {
      background-color: transparent;
      span{
        color: $primaryColor;
      }
    }

    @media (max-width: 440px) {
      display: flex;
    }
  }

  &:after{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-color: white;
    z-index: -1;
  }

  &:before{
    width: 100%;
    height: 100%;
    padding-top: $heightHeader;
    position: fixed;
    top: $heightHeader;
    right: 0;
    content: '';
    background-color: $black;
    opacity: 0.3;
    z-index: -1;
    display: none;
  }

  .nav-scroll{
    overflow: auto;
    padding-top: 10px;
    height: calc(100% - 50px);
  }

  &.minimum{
    width: $widthSideBar;
    min-width: $widthSideBar;
    box-shadow: none;

    .logo{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: $heightItem;
        height: $heightItem;
        object-fit: contain;
      }
      span{
        display: none;
      }
    }

    .nav-item {
      //------- menu co span ---------
      //1
      .menu-item-child {
        .menu-item-icon {
          margin: 0 auto;
        }
        //flex-direction: column;
        span, .nav-item-name{
          //height: 20px;
          //min-height: 20px;
          //white-space: pre;
          //font-size: 1rem;
          //opacity: 0.6;
          display: none;
        }
      }
      .fa-angle-right,.fa-angle-down{
        display: none;
      }
      //2
      //.fa-angle-right,.fa-angle-down, span{
      //  display: none;
      //}

      //------- menu co span ---------

      i{
        font-size: 30px;
        margin: auto;
      }
    }

    .fa-angle-left:before{
      content: '\f039';
    }

    .group-menu-ess{

      .MuiButton-label{
        color: white;
      }

      .MuiButtonGroup-contained{
        border-radius: 0 20px 20px 0 !important;
        background-color: #45AEFD;
        margin-left: -15px;
        width: 50px;
      }

      .ess-inactive{
        display: none;
      }

      .ess-active{
        width: 100%;
      }
    }

    .menu-item-child, .menu-item-child-child{
      transition: all 0.2s;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

  }

  .logo{
    font-size: 1.8rem;
    font-weight: 700;
    position: relative;
    transition: all 0.2s;
    &:hover{
      transform: scale(1.1);
    }

    img{
      width: 50px;
      height: 50px;
      margin: 0 10px;
    }
  }

  .icon-nav{
    font-size: 1.2rem;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background-color: white;
    box-shadow: 0px 0px 7px lightgrey;
    color: grey;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -12px;
    padding: 0 2px;
    top: 30px;
    transition: all 0.2s;
    cursor: pointer;
    &:hover{
      background-color: lightgrey;
      color: white;
    }
  }

  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .menu-item-child, .menu-item-child-child, .menu-item-child span {
    color: $black;
    height: $heightItem;
    min-height: $heightItem;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: 200;
    line-height: 20px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .nav-item-name {
    width: 100%;
    color: $black;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .menu-item-child, .menu-item-child-child{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    opacity: 0.8;

    & > div:first-child {
      width: 22px;
      height: 22px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .menu-item-icon {
      margin: 0 10px;
    }

    svg, img {
      width: 22px !important;
      height: auto;
      path, circle, line {
        fill: #687799;
        stroke: #687799;
      }
    }

    &:hover, &.active {
      opacity: 1;
    }

    &:hover {
      background-color: $hoverColorDefault;

      svg, img {
        transform: scale(1.1);
        path, circle, line {
          fill: #111D5E;
          stroke: #111D5E;
        }
      }
    }

    &.active {
      background-color: $hoverColor;

      img {
        transform: scale(1.1);
        filter: brightness(80%) grayscale(0);;
      }

      svg {
        transform: scale(1.1);
        path, circle, line {
          fill: $primaryColor !important;
          stroke: $primaryColor !important;
        }
      }
      span, .nav-item-name {
        color: $primaryColor;
      }
    }

    svg {
      path[data-type="white"] {
        fill: #FFF !important;
        stroke: #FFF !important;
      }
    }

    i{
      color: $black !important;
      margin-right: 5px;
    }
  }

  .menu-item-child-child{
    padding-left: 20px !important;
    span{
      font-size: 18px;
    }
  }

  .menu-item-child-home{
    height: 80px;
    display: flex;
    align-items: center;
  }

  .menu-item-child.active, .menu-item-child-child.active{
    opacity: 1;
    //font-weight: 700;
    .fas{
      color: #199FFF;
    }
  }

  .nav-item{
    padding: 0;
    transition: all 0.1s;
    overflow: hidden;
    font-size: 20px;
    width: 100%;
    margin: 0;
    //margin: 15px 0 !important;

    //.menu-item-child svg, .menu-item-child img {
    //  width: 22px !important;
    //  height: auto;
    //}
    //
    //.menu-item-child svg {
    //  path, circle, line {
    //    fill: #687799;
    //    stroke: #687799;
    //  }
    //}
    //.menu-item-child.active svg {
    //    //fill: #111D5E;
    //    //stroke: #111D5E;
    //    path, circle, line {
    //      fill: $primaryColor !important;
    //    }
    //}
    //.menu-item-child img {
    //  filter: invert(54%) sepia(3%) saturate(4216%) hue-rotate(184deg) brightness(84%) contrast(85%);
    //}
    //.menu-item-child.active img {
    //  filter: brightness(100%) grayscale(0);
    //
    //}

    .fas, .far{
      color: grey;
      margin-right: 10px;
      font-size: 14px;
    }
  }
}

@media (max-width: 720px) {
  .side-container + .content-container{
    max-width: 100% !important;
    margin-left: 0 !important;
  }
  .side-container{
    position: fixed;
    top: 0;
    left: 0;
    //max-width: 80%;
    //min-width: 80%;
    height: 100%;
    //padding-top: 20px;

    &:before{
      display: block;
    }

    &.minimum:before{
      display: none;
    }

    &.minimum{
      width: 0;
      min-width: 0;

      .img-logo{
        display: none;
      }

      .icon-nav.fa-angle-left{
        display: none;
      }
    }
  }
}

.side-container + .content-container{
  //max-width: calc(100% - 347px);
  //margin-left: 347px;
}

.side-container + .content-container{
  max-width: calc(100% - #{$widthSideBar});
  margin-left: $widthSideBar;
}

.side-container + .content-container.expand{
  max-width: calc(100% - 273px);
  margin-left: 273px;
}

.side-container.minimum + .content-container{
  max-width: calc(100% - #{$widthSideBar});
  margin-left: $widthSideBar;
}

@media (max-width: 720px) {
  .side-nav {

  }
}

@media only screen and (max-width: 992px) {
  .side-container{
    display: flex;
    //.btn-top-menu {
    //    //  display: block;
    //    //}
  }
}
