
.dashboard-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  //max-width: 768px;
  //margin: 0 auto;
  height: 100%;
  .border-info{
    margin: 0 -50px;
    width: 100vw;
    border-bottom: 1px solid #e7e7e7;
  }
  .info-view{
    margin: -15px -50px 0;
    width: 1164px;
    font-family: $fontDefault, sans-serif;
    .view-1,.view-2, .view-3{
      height: 167px;
    }
    .view-2{
      img{
        width: 80px;
        height: 60px;
        object-fit: contain;
      }
    }

    .view-3{
      padding-left: 80px;
      justify-content: center;
      .title-view{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 15px;
        margin-bottom: 10px;
        span{
          color: #9B9B9B;
        }
        .view-3__title-2{
          cursor: pointer;
        }
        .view-3__title-1{
          text-transform: uppercase;
        }
      }
      .list-birthday{
        display: flex;
        min-height: 113px;
      }
      .item-birthday{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 20%;
        img{
          width: 70px;
          height: 70px;
          margin:auto;
          object-fit: contain;
          border-radius: 100px;
          background-image: url("../assets/images/images.jpg") ;
          background-size: 100%;
        }
        span{
          font-weight: 500;
          font-size: 13px;
          margin-top: 5px;
          display: block;
          text-align: center;
        }
      }
    }
    .view-1{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      span:last-child{
        font-size: 20px;
        font-family: $fontDefault, sans-serif;
        margin-top: 10px;
      }
      span:first-child{
        font-size: 24px;
        font-family: $fontDefault, sans-serif;
        text-transform: uppercase;
      }
    }
  }
  .parallelogram-content {
    zoom: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
    .fa-sync-alt{
      width: 20px;
      height: 20px;
      font-size: 20px;
      cursor: pointer;

    }
    span{
      font-size: 14px;
      margin-top: 30px;
      margin-right: 5px;
    }
  }
  .parallelogram {
    transform: skew(-45deg);
    background: #E8E8E8;
    z-index: 0;
  }
  .parallelogram-content,.parallelogram {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .dashboard-container--list{
    width: 100%;
    height: 100%;
    max-width: 768px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .dashboard-container--group{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
  }
  .dashboard-container--wrapper-item {
    height: 160px;
    width: 25%;
    min-width: 134px;
    padding: 10px;
  }
  .dashboard-container--item{
    height: 100%;
    width: 100%;
    background-color: $white;
    border-radius: 22px;
    padding: 10px;
    border: 1px solid #E0E0E0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    .dashboard-container--item__name{
      font-size: 1rem;
      font-weight: 200;
      white-space: pre-line;
      text-align: center;
      height: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dashboard-container--item__icon{
      width: 100%;
      height: 50%;
      display: flex;
      margin-bottom: 12px;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;

      img {
        width: 38px;
        height: 65px;
        object-fit: contain;
        object-position: 0 16px;
        margin-bottom: -12px;
      }
    }

    &:hover {
      box-shadow: 0 7px 10px #00000045;
    }
  }
  .slick-dots li button:before, .slick-next:before, .slick-prev:before{
    font-family: inherit;
    color: grey;
  }
  .slick-next, .slick-prev {
    width: 30px;
    height: 30px;
    padding: 0;
    top: 45%;
    position: absolute;
    &:before {
      content: none;
    }
  }
}

@media (max-width: 720px) {
  .dashboard-container{
    max-width: 95%;
    margin: 20px auto;
    .dashboard-container--group{
      justify-content: center;
    }
    .dashboard-container--wrapper-item {
      height: 134px;
    }
    .dashboard-container--item__icon{
      width: 40px !important;
      height: 40px !important;
    }
    .dashboard-container--item__name{
      font-size: 12px !important;
      //white-space: pre !important;
    }
  }
}

@keyframes pulse
{
  0% {
    box-shadow: 0 0 0 0 #00000028;
  }
  100% {
    box-shadow: 0 0 0 28px rgba(0, 0, 0, 0);
  }
}
